.app {
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.logo img {
  height: 80px; /* Ajuste conforme necessário */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin-left: 20px;
}

.navbar a {
  text-decoration: none;
  color: #333;
}

.navbar a:hover {
  text-decoration: underline;
}

main {
  padding: 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para a página de Perguntas */
/* Ajuste no container para centralizar tudo */
.container-responder {
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.tabulaquest {
  width: 100%;
  max-width: 100%; 
  height: auto; 
  object-fit: contain; 
}
/* Melhor espaçamento entre títulos */
.container-responder h1 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 15px;
}

.container-responder h3 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

/* Ajuste das perguntas */
.question-block {
  width: 100%;
  text-align: left;
  margin-bottom: 10px; /* Reduz espaço entre perguntas */
}

.question-block p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px; /* Reduz espaço entre pergunta e opções */
}

/* Grupo das opções de resposta */
.radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px; 
}

.radio-option input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-option label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.radio-option input[type="radio"]:checked + label:before {
  border-color: #2196f3;
}

.radio-option input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2196f3;
  transition: all 0.2s ease;
}

.radio-option label {
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  margin: 0;
  line-height: 1.4;
}

.radio-option:hover label:before {
  border-color: #1976d2;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

button[type="submit"] {
  font-size: 18px;
  padding: 12px;
  margin-top: 15px;
}
button:hover {
  background-color: #45a049;
}

button[type="button"] {
  background-color: #f44336;
}

button[type="button"]:hover {
  background-color: #e53935;
}

button[type="submit"] {
  background-color: #2196f3;
}

button[type="submit"]:hover {
  background-color: #1976d2;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Adicione estas regras de estilo ao App.css para remover o sublinhado dos links */
a {
  text-decoration: none;
  color: inherit; /* Para manter a cor do link igual ao texto */
}

a:hover {
  text-decoration: none;/* Opcional: adicionar sublinhado ao passar o mouse */
}

/* Outros estilos */
button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

li button {
  margin-left: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
  }

  .navbar ul {
    flex-direction: column;
    align-items: center;
  }

  .navbar li {
    margin: 10px 0;
  }

  .container {
    max-width: 100%;
    padding: 10px;
  }

  .option-row {
    flex-direction: column;
    align-items: stretch;
  }

  .option-row input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }
}
