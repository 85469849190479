.login-container {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  div {
    margin-bottom: 15px;
  }
  
  label {
    margin-bottom: 5px;
  }
  
  input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width :calc(100% - 20px);
  }
  
  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .radio-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .radio-input {
    display: none; 
  }
  
  .radio-custom {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transition: background-color 0.2s;
  }
  
  .radio-input:checked + .radio-custom {
    background-color: #000; 
  }
  
  .radio-custom::after {
    content: "";
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    display: none;
  }
  
  .radio-input:checked + .radio-custom::after {
    display: block; /* Mostra a bolinha branca quando o radio está marcado */
  }

  .radio-group {
    display: flex;
    flex-direction: column; /* Mantém as opções uma abaixo da outra */
    gap: 10px; /* Espaçamento entre as opções */
  }
  
  .radio-option {
    display: flex;
    align-items: center;
    width: 100%; /* Garante que ocupe toda a linha */
  }
  
  .radio-option input {
    flex: 0 0 40px; /* Fixa o tamanho do checkbox/radio */
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .radio-option label {
    flex: 1; /* Ocupa o restante do espaço */
    text-align: left; /* Alinha o texto corretamente */
  }
  

  